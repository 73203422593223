import React, { useEffect, useState } from "react";
import ActionBox from "../components/ActionBox";
import { toast } from "react-toastify";
import { fetchServices } from "../api/main";
import Loading from "../components/Loading";

const Service = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleFetchServices = async () => {
      setLoading(true);
      try {
        const res = await fetchServices();

        setServices(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconFilter = (name) => {
    if (name === "Customer Experience") {
      return "flaticon-author";
    } else if (name === "Content Creation") {
      return "flaticon-data";
    } else if (name === "Online Advertisement") {
      return "flaticon-design";
    } else if (name === "Social Media Management") {
      return "flaticon-icon-149196";
    } else if (name === "Lilyadd") {
      return "flaticon-chart";
    } else {
      return "flaticon-group";
    }
  };
  return (
    <div>
      <section className="header-inner bg-overlay-black-50 headerImgAlt">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <div className="header-inner-title text-center position-relative">
                <h1 className="text-white fw-normal">
                  Digital Marketing Services
                </h1>
                <p className="text-white mb-0">
                  Award-winning website design &amp; creative digital agency
                  services
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          {loading ? (
            <Loading />
          ) : (
            <div className="row">
              {services?.map((item) => {
                return (
                  <div key={item?.id} className="col-lg-4 col-md-6 mb-4">
                    <div className="feature-info feature-info-style-02 h-100">
                      <div className="feature-info-icon">
                        <i className={iconFilter(item?.title)} />
                        <h5 className="mb-0 ms-4 feature-info-title">
                          {item?.title}
                        </h5>
                      </div>
                      <div className="feature-info-content">
                        <p className="mb-0">{item?.disc}</p>
                        {/* <a href="service-detail.html" className="icon-btn">
                      <i className="fas fa-long-arrow-alt-right" />
                    </a> */}
                      </div>
                      {/* <div
                    className="feature-info-bg-img"
                    style={{
                      backgroundImage: 'url("images/feature-info/01.jpg")',
                    }}
                  /> */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className="row mt-5">
            <div className="col-12 d-md-flex justify-content-center align-items-center">
              <p className="mb-3 mb-md-0 mx-0 mx-md-3">
                Start now! Your big opportunity may be right where you are!
              </p>
              <a
                href="#"
                className="btn btn-primary-round btn-round mx-0 mx-md-3"
              >
                See All Services
                <i className="fas fa-arrow-right ps-3" />
              </a>
            </div>
          </div> */}
        </div>
      </section>

      <ActionBox />
    </div>
  );
};

export default Service;
