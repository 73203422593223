import React, { useEffect, useState } from "react";
import ActionBox from "../components/ActionBox";
import { useSearchParams } from "react-router-dom";
// import blog1 from "../assets/images/blog/1.jpg";
// import blog2 from "../assets/images/blog/2.jpg";
// import blog3 from "../assets/images/blog/3.jpg";
// import blog4 from "../assets/images/blog/4.jpg";
// import blog5 from "../assets/images/blog/5.jpg";
// import blog6 from "../assets/images/blog/6.jpg";
import { fetchBlog } from "../api/main";
import { toast } from "react-toastify";
import Loading from "../components/Loading";

const Blogdetail = () => {
  const [id, setId] = useState(null);
  // const [blog, setBlog] = useState(null);
  // const [play, setPlay] = useState(false);
  const [blog, setBlog] = useState([]);
  const [blogLoading, setBlogLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const mainId = searchParams.get("id");

  useEffect(() => {
    if (mainId) {
      setId(mainId);
    }
  }, [mainId]);

  // useEffect(() => {
  //   if (id) {
  //     const newBlog = blogs.filter((cur) => cur.id === id);
  //     if (newBlog.length > 0) {
  //       setBlog(newBlog[0]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  useEffect(() => {
    const handleFetchBlogs = async () => {
      if (id) {
        setBlogLoading(true);
        try {
          const res = await fetchBlog(id);

          setBlog(res.data);
          setBlogLoading(false);
        } catch (err) {
          setBlogLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  console.log("id", id);

  return (
    <div>
      {blogLoading ? (
        <Loading />
      ) : (
        <>
          <section
            className="header-inner bg-overlay-black-50 blogBg"
            // style={{ backgroundImage: 'url("images/header-inner/04.jpg")' }}
          >
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-md-12">
                  <div className="header-inner-title text-center position-relative">
                    <h1 className="text-white fw-normal mb-0">{blog?.title}</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="space-ptb">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div className="blog-detail">
                    <div className="blog-post mb-4 mb-md-5">
                      <div className="blog-post-image">
                        {!blog?.youtube && (
                          <img
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: 400,
                            }}
                            className="img-fluid"
                            src={blog?.coverUrl}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="blog-post-content">
                        <div className="blog-post-info">
                          <div className="blog-post-author">
                            <span
                              style={{ cursor: "auto" }}
                              className="btn btn-light-round btn-round text-primary"
                            >
                              {blog?.tag}
                            </span>
                          </div>
                          <div className="blog-post-date">
                            <span>{blog?.createdAt?.slice(0, 10)}</span>
                          </div>
                        </div>
                        <div className="blog-post-details">
                          <h5 className="blog-post-title">{blog?.title}</h5>
                          <p
                            className="mb-4"
                            dangerouslySetInnerHTML={{ __html: blog?.content }}
                          ></p>
                          {/* <p className="mb-md-5 mb-4">
                        You carry on doing the same things, living the same way
                        and dealing with this thing in the same way as you have
                        been doing. If you were choose the path to the right,
                        the right path, there are new possibilities,
                        achievement, freedom of mind, positive and progressive
                        implications. Think about that as you stand at this
                        place where the path splits. You want to make a decision
                        and commit to one of these paths. Before you make that
                        decision, we are going to see what each path holds for
                        your future.
                      </p>
                      <div className="d-sm-flex bg-light border-radius p-4 p-md-5 mb-md-5 mb-4">
                        <i className="fas fa-quote-left pe-4 fa-6x text-primary" />
                        <p className="mb-0 text-dark">
                          Politics can be attributed to his perseverance to
                          overcome his personal liabilities, and his desire to
                          constantly become better. Next time you really want to
                          achieve something, take time to focus on your own
                          personal journal. What is your temptation that is
                          standing in your wayv to greatness.
                        </p>
                      </div>
                      <ul className="list list-unstyled">
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Use a past defeat as a motivator. Remind yourself
                            you have nowhere to go except
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Give yourself the power of responsibility.{" "}
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Remind yourself the only thing stopping you is
                            yourself.
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            Make a list of your achievements toward your
                            long-term
                          </span>
                        </li>
                        <li className="d-flex">
                          <i className="fas fa-check pe-2 pt-1 text-primary" />
                          <span>
                            goal and remind yourself that intentions don’t
                            count, only action’s.
                          </span>
                        </li>
                      </ul> */}
                        </div>
                        <div class="blog-post mb-4 mb-md-5">
                          <div
                            class="blog-post-image position-relative"
                            style={{ cursor: "pointer" }}
                          >
                            <iframe
                              style={{
                                height: 400,
                                width: "100%",
                                borderRadius: 5,
                              }}
                              // allow="autoplay; encrypted-media"
                              allowFullScreen
                              title="Youtube player"
                              // sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                              src={`https://youtube.com/embed/${blog?.youtube}?autoplay=0`}
                            ></iframe>
                            {/* {play ? (
                          <iframe
                            style={{
                              height: 400,
                              width: "100%",
                              borderRadius: 5,
                            }}
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            title="Youtube player"
                            // sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                            src={`https://youtube.com/embed/LgvseYYhqU0?autoplay=1&mute=1`}
                          ></iframe>
                        ) : (
                          <>
                            <img
                              class="img-fluid"
                              src={blog?.img}
                              alt=""
                              style={{
                                height: 400,
                                objectFit: "cover",
                                width: "100%",
                              }}
                            />
                            <span
                              class="icon-btn icon-btn-lg icon-btn-all-center btn-animation"
                              onClick={() => setPlay(true)}
                            >
                              <i class="fas fa-play fa-1x"></i>
                            </span>
                          </>
                        )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      <ActionBox />
    </div>
  );
};

export default Blogdetail;
