import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";
import ActionBox from "../components/ActionBox";
import partner from "../assets/images/partner.jpeg";
import podcast from "../assets/images/podcast.jpeg";
import team2 from "../assets/images/team2.jpg";
import team3 from "../assets/images/team3.jpg";
import how2 from "../assets/images/how2.jpg";
import blog1 from "../assets/images/blog/1.jpg";
import blog2 from "../assets/images/blog/2.jpg";
import blog3 from "../assets/images/blog/3.jpg";
import yv1 from "../assets/images/yv1.jpg";
import yv2 from "../assets/images/yv2.jpg";
import yv3 from "../assets/images/yv3.jpg";
import logo1 from "../assets/images/logos/1.jpg";
import logo2 from "../assets/images/logos/2.jpeg";
import logo3 from "../assets/images/logos/3.png";
import logo4 from "../assets/images/logos/4.jpeg";
import logo5 from "../assets/images/logos/5.png";
import logo6 from "../assets/images/logos/6.jpg";
import logo7 from "../assets/images/logos/7.png";
import { toast } from "react-toastify";
import { fetchBlogs, fetchServices } from "../api/main";
import Loading from "../components/Loading";

const Home = () => {
  const [services, setServices] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);

  const logos = [
    "images/Downloads/PORTFOLIOS WORKED WITH/AUTOSTAR BRAND.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/GREENBANK BRAND.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/GTB.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/NEXTIER POWER.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/PB.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/PDP.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/BAYELSA LOGO.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/RCCG 00.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/bhis logo.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/SHELL.png",
    "images/Downloads/PORTFOLIOS WORKED WITH/SOF.png",
    "images/Downloads/LOGOS/logo1.png",
    "images/Downloads/LOGOS/logo2.png",
    "images/Downloads/LOGOS/logo3.png",
    "images/Downloads/LOGOS/logo4.png",
    "images/Downloads/LOGOS/logo5.png",
    "images/Downloads/LOGOS/logo6.png",
    "images/Downloads/LOGOS/logo7.png",
    "images/Downloads/LOGOS/logo8.png",
    "images/Downloads/LOGOS/logo9.png",
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
  ];

  // const blogs = [
  //   {
  //     id: "hnbtr54t545gtvr54",
  //     title: "Top 5 Business Branding Mistakes",
  //     desc: `Branding is a vital part of business, mostly done at the beginning of a
  //            business venture. However, these are the top 5 mistakes business owners
  //            make and how to correct them.`,
  //     img: blog1,
  //     tag: "Business",
  //     date: "May 16, 2024",
  //   },
  //   {
  //     id: "sdsdfr38744g3h4g3h",
  //     title: "How to turn your Brand into a Legacy",
  //     desc: `Starting your brand sometimes have future purposes but sometimes the future is not beyond you. Some brands outlive the visioner while some don’t. If you think your brand is meant to outlive you, you need to watch this video to know how to turn your brand into a legacy that will outlive you and your generation.`,
  //     img: blog2,
  //     tag: "Business",
  //     date: "Feb 23, 2024",
  //   },
  //   {
  //     id: "alkiae7232k2j3e89w32",
  //     title: "How to Switch from Employee to Business owner",
  //     desc: `Been an employee is not the end of the road, that your business dream can be born, this transition is possible! Watch Solutions Podcast to know how!`,
  //     img: blog3,
  //     tag: "Business",
  //     date: "Dec 3, 2023",
  //   },
  // ];

  useEffect(() => {
    const handleFetchServices = async () => {
      setLoading(true);
      try {
        const res = await fetchServices();

        setServices(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleFetchBlogs = async () => {
      setBlogLoading(true);
      try {
        const res = await fetchBlogs(1, 3);

        setBlogs(res.data?.blogs);
        setBlogLoading(false);
      } catch (err) {
        setBlogLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconFilter = (name) => {
    if (name === "Customer Experience") {
      return "flaticon-author";
    } else if (name === "Content Creation") {
      return "flaticon-data";
    } else if (name === "Online Advertisement") {
      return "flaticon-design";
    } else if (name === "Social Media Management") {
      return "flaticon-icon-149196";
    } else if (name === "Lilyadd") {
      return "flaticon-chart";
    } else {
      return "flaticon-group";
    }
  };

  return (
    <>
      <Banner />

      {/*=================================
    client-logo */}
      <section className="mt-n5 z-index-9 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="our-clients our-clients-style-02 bg-dark">
                <div className="client-title ps-4">
                  <h5 className="text-white">Our Clients</h5>
                  <div className="svg-item"></div>
                </div>
                <div className="brand-logo ps-4">
                  <div
                    className="owl-carousel testimonial-center owl-nav-bottom-center"
                    data-nav-arrow="false"
                    data-items={5}
                    data-md-items={4}
                    data-sm-items={4}
                    data-xs-items={3}
                    data-xx-items={2}
                    data-space={40}
                    data-autoheight="true"
                  >
                    {logos.map((logo) => {
                      return (
                        <div
                          style={{
                            background: "#fff",
                            borderRadius: 10,
                            height: "100%",
                          }}
                          key={logo}
                          className="item"
                        >
                          <img
                            className="img-fluid center-block mx-auto"
                            src={logo}
                            style={{ maxHeight: 70 }}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="client-btn">
                  <Link
                    to="/portfolio"
                    className="btn btn-primary-round btn-round text-white"
                  >
                    our clients
                    <i className="fas fa-arrow-right ps-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    client-logo  */}

      {/*=================================
    Portfolio Category */}
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <div className="section-title text-center">
                <h2>
                  Well-structured and Proven Services That Can Help Your
                  Business Grow
                </h2>
                <p>Fortune 100 companies and established brands trust us.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-sm-5 mb-4">
              <div className="category-box category-box-style-02 text-center">
                <div className="category-icon">
                  <i className="flaticon-monitor" />
                  <h5 className="category-title mb-0">Web Development</h5>
                </div>
                <ul className="category-list">
                  <li>
                    <span>Bespoke Website design</span>
                  </li>
                  <li>
                    <span>Powerful Web Applications </span>
                  </li>
                  <li>
                    <span>Front-End development</span>
                  </li>
                  <li>
                    <span>E-commerce Solutions</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-sm-5 mb-4">
              <div className="category-box category-box-style-02 text-center active">
                <div className="category-icon">
                  <i className="flaticon-mobile-phone" />
                  <h5 className="category-title mb-0">Mobile Development</h5>
                </div>
                <ul className="category-list">
                  <li>
                    <span>IOS</span>
                  </li>
                  <li>
                    <span>Andriod</span>
                  </li>
                  <li>
                    <span>User Testing</span>
                  </li>
                  <li>
                    <span>Hybrid Platform</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-sm-5 mb-4">
              <div className="category-box category-box-style-02 text-center">
                <div className="category-icon">
                  <i className="flaticon-icon-253674" />
                  <h5 className="category-title mb-0">Branding Services</h5>
                </div>
                <ul className="category-list">
                  <li>
                    <span>Logo design</span>
                  </li>
                  <li>
                    <span>UI/UX design</span>
                  </li>
                  <li>
                    <span>Visual design</span>
                  </li>
                  <li>
                    <span>Brand collateral</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-sm-5 mb-4">
              <div className="category-box category-box-style-02 text-center">
                <div className="category-icon">
                  <i className="flaticon-icon-149196" />
                  <h5 className="category-title mb-0">Digital Marketing</h5>
                </div>
                <ul className="category-list">
                  <li>
                    <span>Strategic Marketing Campaigns</span>
                  </li>
                  <li>
                    <span>Paid media</span>
                  </li>
                  <li>
                    <span>Influencer marketing</span>
                  </li>
                  <li>
                    <span>Social Media Marketing</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-md-flex justify-content-center align-items-center">
              <p className="mb-3 mb-md-0 mx-0 mx-md-3">
                Established in 2018, we specialize in delivering comprehensive
                business support services designed to elevate your brand and
                accelerate growth.
              </p>
              <Link
                to="/pricing"
                href="#"
                className="btn btn-primary-round btn-round mx-0 mx-md-3"
              >
                Get A Quote
                <i className="fas fa-arrow-right ps-3" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    Portfolio Category */}

      {/*=================================
    Case Study */}
      <section className="space-ptb bg-dark-half-lg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <div className="section-title text-center position-relative">
                <h2 className="text-white">
                  <span className="text-primary">What's next?</span> our latest
                  client stories
                </h2>
                <p className="lead text-white">
                  We love what we do. Our clients tell us the same thing.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 order-lg-1 order-1">
              <div className="case-study">
                <div
                  className="case-study-img case-study-lg"
                  style={{
                    backgroundImage:
                      'url("images/Downloads/BRANDS/BASTION BRAND MOCKUP.png")',
                    // backgroundSize: "150%",
                  }}
                ></div>
                <div className="case-study-info">
                  <span className="case-study-title fw-bold">BASTION</span>
                  {/* <span className="case-study-services text-primary">
                    Music
                  </span>
                  <p>
                    If success is a process with a number of defined steps, then
                    it is just like any other process.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 order-lg-2 order-3">
              <div className="row">
                <div className="col-sm-6 col-lg-12">
                  <div className="case-study">
                    <div
                      className="case-study-img"
                      style={{
                        backgroundImage:
                          'url("images/Downloads/BRANDS/COLORIZE BRAND MOCKUP.png")',
                        backgroundRepeat: "repeat",
                        height: "100%",
                        width: "100%",
                        backgroundSize: "200%",
                      }}
                    ></div>
                    <div className="case-study-info">
                      <span className="case-study-title fw-bold" href="#">
                        COLORIZE
                      </span>
                      {/* <span className="case-study-services text-primary">
                        Education
                      </span>
                      <p>
                        We all carry a lot of baggage, thanks to our
                        upbringing...
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-12">
                  <div className="case-study">
                    <div
                      className="case-study-img"
                      style={{
                        backgroundImage:
                          'url("images/Downloads/BRANDS/BRIGHT HERITAGE BRAND MOCKUP.png")',
                        height: "100%",
                        width: "100%",
                        backgroundSize: "200%",
                      }}
                    ></div>
                    <div className="case-study-info">
                      <span className="case-study-title" href="#">
                        BRIGHT HERITAGE
                      </span>
                      {/* <span className="case-study-services text-primary">
                        Pet Care
                      </span>
                      <p>
                        You will drift aimlessly until you arrive back at the
                        original dock
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 order-lg-3 order-2">
              <div className="case-study">
                <div
                  className="case-study-img case-study-lg"
                  style={{
                    backgroundImage:
                      'url("images/Downloads/BRANDS/KOPI BRAND MOCKUP.png")',
                    height: "100%",
                    width: "100%",
                    backgroundSize: "250%",
                  }}
                ></div>
                <div className="case-study-info">
                  <span className="case-study-title fw-bold" href="#">
                    KOPI
                  </span>
                  {/* <span className="case-study-services text-primary">
                    Beverage
                  </span> */}
                  {/* <p>
                    It is truly amazing the damage that we, as parents, can
                    inflict on our children...
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 d-flex justify-content-center mt-0 mt-md-4">
              <Link href="#" className="btn btn-primary-round btn-round mx-3">
                View all Case Study
                <i className="fas fa-arrow-right ps-3" />
              </Link>
            </div>
          </div> */}
        </div>
      </section>
      {/*=================================
    Case Study */}

      {/*=================================
    Tab */}
      <section className="space-pb">
        <div className="container">
          <div className="row justify-content-center pb-4 pb-md-5">
            <div className="col-lg-10">
              <div className="d-md-flex align-items-center">
                <div className="me-4">
                  <p className="mb-0">Established in</p>
                  <h3 className="display-2 fw-bold text-primary mb-0">2018</h3>
                </div>
                <div className="me-3">
                  <h2 className="mb-0">
                    We collaborate with industry leaders and innovators to
                    deliver impactful solutions:
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-flex h-100">
                <div
                  className="nav flex-column nav-pills w-100 align-self-lg-center"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="nav-link"
                    id="mission-and-vision-tab"
                    data-bs-toggle="pill"
                    href="#mission-and-vision"
                    role="tab"
                    aria-controls="mission-and-vision"
                    aria-selected="true"
                  >
                    <span className="data-hover" data-title="Our Partners">
                      Our Partners
                    </span>
                  </a>
                  <a
                    className="nav-link active"
                    id="our-challenges-tab"
                    data-bs-toggle="pill"
                    href="#our-challenges"
                    role="tab"
                    aria-controls="our-challenges"
                    aria-selected="false"
                  >
                    <span className="data-hover" data-title="Join our podcast">
                      Join our podcast
                    </span>
                  </a>
                  <a
                    className="nav-link"
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    href="#technology-partners"
                    role="tab"
                    aria-controls="technology-partners"
                    aria-selected="false"
                  >
                    <span
                      className="data-hover"
                      data-title="Enroll for training"
                    >
                      Enroll for training
                    </span>
                  </a>
                  <a
                    className="nav-link"
                    id="meet-the-our-team-tab"
                    data-bs-toggle="pill"
                    href="#meet-the-our-team"
                    role="tab"
                    aria-controls="meet-the-our-team"
                    aria-selected="false"
                  >
                    <span className="data-hover" data-title="Meet our team">
                      Meet our team
                    </span>
                  </a>
                  <a
                    className="nav-link"
                    id="careers-with-us-tab"
                    data-bs-toggle="pill"
                    href="#careers-with-us"
                    role="tab"
                    aria-controls="careers-with-us"
                    aria-selected="false"
                  >
                    <span className="data-hover" data-title="Careers with us">
                      Careers with us
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-9 mt-3 mt-md-0">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade"
                  id="mission-and-vision"
                  role="tabpanel"
                  aria-labelledby="mission-and-vision-tab"
                >
                  <div className="row">
                    <div className="col-sm-5 align-self-lg-center mb-4 mb-sm-0">
                      <img className="img-fluid rounded" src={partner} alt="" />
                    </div>
                    <div className="col-sm-7 align-self-start align-self-lg-center ps-md-0 ps-lg-5">
                      <h4>
                        <i className="flaticon-target text-primary me-2" /> Our
                        Partners
                      </h4>
                      <p className="mb-4">
                        Engage with our thought-provoking podcast series that
                        explores diverse facets of business and society:
                      </p>
                      <div className="row">
                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/06.svg"
                            alt=""
                          />
                        </div>

                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/07.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/Downloads/Order Food Logo .png"
                            alt=""
                          />
                        </div>
                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/08.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/02.svg"
                            alt=""
                          />
                        </div>

                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/01.svg"
                            alt=""
                          />
                        </div>
                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/03.svg"
                            alt=""
                          />
                        </div>

                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/04.svg"
                            alt=""
                          />
                        </div>
                        {/* <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/01.svg"
                            alt=""
                          />
                        </div> */}
                        <div className="col-lg-4 mb-4">
                          <img
                            className="img-fluid grayscale p-2"
                            src="images/client-logo/05.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="our-challenges"
                  role="tabpanel"
                  aria-labelledby="our-challenges-tab"
                >
                  <div className="row">
                    <div className="col-sm-5 align-self-lg-center mb-4 mb-sm-0">
                      <img className="img-fluid rounded" src={podcast} alt="" />
                    </div>
                    <div className="col-sm-7 align-self-start align-self-lg-center ps-md-0 ps-lg-5">
                      <h4>
                        <i className="flaticon-target text-primary me-2" />{" "}
                        Solutions Podcast:
                      </h4>
                      <p className="mb-4">
                        Gain valuable business insights and strategies from
                        industry experts and thought leaders. Gain valuable
                        business insights and strategies from industry experts
                        and thought leaders.
                      </p>
                      <h4>
                        <i className="flaticon-target text-primary me-2" />{" "}
                        Interactive Sessions:
                      </h4>
                      <p className="mb-4">
                        Dive deep into engaging discussions with business
                        leaders sharing their perspectives and experiences.
                      </p>
                      <h4>
                        <i className="flaticon-target text-primary me-2" /> Real
                        Talk:
                      </h4>
                      <p className="mb-4">
                        Dive into candid conversations on real-life challenges
                        and solutions. Street Waka: Explore grassroots
                        perspectives and stories from the heart of society.
                      </p>
                      {/* <a href="#" className="btn btn-primary-round btn-round">
                        Learn More
                        <i className="fas fa-arrow-right ps-3" />
                      </a> */}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="technology-partners"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-sm-5 align-self-lg-center mb-4 mb-sm-0">
                      <img className="img-fluid rounded" src={how2} alt="" />
                    </div>
                    <div className="col-sm-7 align-self-start align-self-lg-center ps-md-0 ps-lg-5">
                      <h4 className="text-dark">Enroll For Training</h4>
                      <p>
                        Are you ready to take your skills to the next level? At
                        Lilysolutions Limited, we offer cutting-edge training
                        programs designed to empower you with the latest tools
                        and knowledge in digital marketing, web development,
                        branding, business support, and more. Whether you're
                        looking to enhance your career prospects or elevate your
                        business acumen, our training courses cater to diverse
                        skill levels and industry needs.
                      </p>
                      <p>
                        Unlock Your Potential with Our Expert Training Programs
                      </p>
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <ul className="list list-unstyled">
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span> Web Development</span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>3D Animation</span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Cinematography</span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span> Photography</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="list list-unstyled">
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Digital Marketing</span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Graphic Designing</span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span> Drone Piloting</span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Video Editing</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="mt-4">
                        <a
                          href="https://app.lilysolutions.org/training-application"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary-round btn-round"
                        >
                          Enroll now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="meet-the-our-team"
                  role="tabpanel"
                  aria-labelledby="meet-the-our-team-tab"
                >
                  <div className="row">
                    <div className="col-sm-5 align-self-lg-center mb-4 mb-sm-0">
                      <img
                        className="img-fluid rounded"
                        src={team2}
                        alt=""
                        style={{ minHeight: 400, objectFit: "cover" }}
                      />
                    </div>
                    <div className="col-sm-7 align-self-start align-self-lg-center ps-md-0 ps-lg-5">
                      <div className="row">
                        <div className="col-12 mb-4">
                          <h4 className="text-dark">Meet Our Team</h4>
                          <p>
                            Get to know the passionate individuals who drive
                            Lilysolutions Limited forward. Meet our dedicated
                            team of experts who are committed to delivering
                            exceptional results and exceeding client
                            expectations:
                          </p>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-6 col-6">
                          <div className="team">
                            <div className="team-bg" />
                            <div className="team-img">
                              <img
                                className="img-fluid"
                                src="images/team/01.jpg"
                                alt=""
                              />
                            </div>
                            <div className="team-info">
                              <span className="team-name">Lanre Olifade</span>
                              <p>Co-Founder/CEO</p>
                              <ul className="list-unstyled">
                                <li>
                                  <span>
                                    <i className="fab fa-facebook-f" />
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fab fa-twitter" />
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fab fa-linkedin-in" />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-6 col-6">
                          <div className="team">
                            <div className="team-bg" />
                            <div className="team-img">
                              <img
                                className="img-fluid"
                                src="images/team/07.jpg"
                                alt=""
                              />
                            </div>
                            <div className="team-info">
                              <span className="team-name">
                                William Chidiebere
                              </span>
                              <p>Business Development Officer</p>
                              <ul className="list-unstyled">
                                <li>
                                  <span>
                                    <i className="fab fa-facebook-f" />
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fab fa-twitter" />
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fab fa-linkedin-in" />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-sm-6 col-6">
                          <div className="team">
                            <div className="team-bg" />
                            <div className="team-img">
                              <img
                                className="img-fluid"
                                src="images/team/03.jpg"
                                alt=""
                              />
                            </div>
                            <div className="team-info">
                              <span className="team-name">Giwa Muhammad</span>
                              <p>Backend Engineer</p>
                              <ul className="list-unstyled">
                                <li>
                                  <span>
                                    <i className="fab fa-facebook-f" />
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fab fa-twitter" />
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <i className="fab fa-linkedin-in" />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <Link
                            className="btn btn-primary-round btn-round"
                            to="/our-team"
                          >
                            View Our Team
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="careers-with-us"
                  role="tabpanel"
                  aria-labelledby="careers-with-us-tab"
                >
                  <div className="row">
                    <div className="col-sm-5 align-self-lg-center mb-4 mb-sm-0">
                      <img
                        className="img-fluid rounded"
                        src={team3}
                        style={{ minHeight: 400, objectFit: "cover" }}
                        alt=""
                      />
                    </div>
                    <div className="col-sm-7 align-self-start align-self-lg-center ps-md-0 ps-lg-5">
                      <h4 className="mb-2">Are you the best?</h4>
                      <p className="mb-4">
                        Join a dynamic team that values innovation, creativity,
                        and professional growth. Discover exciting career
                        opportunities at Lilysolutions Limited and become a part
                        of our success story.
                      </p>
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <ul className="list list-unstyled">
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Digital Marketing Specialist </span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Web Developer </span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Brand Strategist </span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Business Development Manager </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="list list-unstyled">
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Public Relations Officer </span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Content Creator </span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>Graphic Designer </span>
                            </li>
                            <li className="d-flex">
                              <i className="fas fa-check pe-2 pt-1 text-primary" />
                              <span>SEO Specialist</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <Link
                        to="/"
                        className="btn btn-primary-round btn-round mt-2"
                      >
                        View Available Positions
                        <i className="fas fa-arrow-right ps-3" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    Tab */}

      {/*=================================
    Service */}
      <section className="space-ptb bg-dark  dark-background category category-list-style-01">
        <div className="container">
          <div className="row align-items-center pb-4 pb-md-5">
            <div className="col-lg-6">
              <h2 className="text-white mb-2 mb-lg-0">
                We excel in our industry so that you can get in yours.
              </h2>
            </div>
            <div className="col-lg-6">
              <p className="lead text-white mb-0">
                We offer expertise across various blog. See some of the blog we
                have vast experience working in as a full-service marketing
                agency.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <ul className="category-list">
                <li>
                  <span>Automotive</span>
                </li>
                <li>
                  <span>Communication Services</span>
                </li>
                <li>
                  <span>Retail</span>
                </li>
                <li>
                  <span>Education</span>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-3 mt-4 mt-sm-0">
              <ul className="category-list">
                <li>
                  <span>Financial Services</span>
                </li>
                <li>
                  <span>Publishing</span>
                </li>
                <li>
                  <span>FMCG</span>
                </li>
                <li>
                  <span>Travel</span>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0">
              <ul className="category-list">
                <li>
                  <span>Media and Entertainment</span>
                </li>
                <li>
                  <span>Logistics and Distribution</span>
                </li>
                <li>
                  <span>Utilities</span>
                </li>
                <li>
                  <span>Health Care</span>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0">
              <ul className="category-list">
                <li>
                  <span>Hi-Tech</span>
                </li>
                <li>
                  <span>Publishing</span>
                </li>
                <li>
                  <span>Industrial &amp; Manufacturing</span>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="row mt-4 mt-md-5">
            <div className="col-12 d-md-flex justify-content-center align-items-center">
              <p className="mb-3 mb-md-0 mx-0 mx-md-3 text-white">
                We provide solutions for all types of big blog
              </p>
              <a
                href="#"
                className="btn btn-primary-round text-white btn-round mx-0 mx-md-3"
              >
                Browse blog
                <i className="fas fa-arrow-right ps-3" />
              </a>
            </div>
          </div> */}
        </div>
      </section>
      {/*=================================
    Service */}

      {/*=================================
    Service */}
      <section className="space-ptb bg-light position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title is-sticky">
                <h2>This is what we do and we do it perfectly.</h2>
                <p className="mb-4">
                  Please take a look at the range of <b>IT solution</b> services
                  we offer to your brand as a full-service leading marketing
                  agency.
                </p>
                <Link to="/service" className="btn btn-primary-round btn-round">
                  See All Services<i className="fas fa-arrow-right ps-3"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-8">
              {/* {services?.map((item, i) => {
                    return (
                      <div
                        className={
                          i === 0
                            ? "feature-info feature-info-style-01"
                            : "feature-info feature-info-style-01 mt-4 mt-lg-5"
                        }
                      >
                        <div className="feature-info-icon">
                          <i className="flaticon-data"></i>
                        </div>
                        <div className="feature-info-content">
                          <h5 className="mb-3 feature-info-title">
                            {item?.title}
                          </h5>
                          <p className="mb-0">{item?.disc}</p>
                        </div>
                      </div>
                    );
                  })} */}

              {loading ? (
                <Loading />
              ) : (
                <div className="row">
                  <div className="col-sm-6">
                    {services?.slice(0, 3).map((item, i) => {
                      return (
                        <div
                          key={item?.id}
                          className={
                            i === 0
                              ? "feature-info feature-info-style-01"
                              : "feature-info feature-info-style-01 mt-4 mt-lg-5"
                          }
                        >
                          <div className="feature-info-icon">
                            <i className={iconFilter(item?.title)}></i>
                          </div>
                          <div className="feature-info-content">
                            <h5 className="mb-3 feature-info-title">
                              {item?.title}
                            </h5>
                            <p className="mb-0">{item?.disc}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-sm-6">
                    {services?.slice(3, 6).map((item, i) => {
                      return (
                        <div
                          key={item?.id}
                          className={
                            "feature-info feature-info-style-01 mt-4 mt-lg-5"
                          }
                        >
                          <div className="feature-info-icon">
                            <i className={iconFilter(item?.title)}></i>
                          </div>
                          <div className="feature-info-content">
                            <h5 className="mb-3 feature-info-title">
                              {item?.title}
                            </h5>
                            <p className="mb-0">{item?.disc}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    Service */}

      {/*=================================
    Testimonial and Brands */}
      <section className="space-ptb">
        <div className="container">
          <div className="row mb-0 mb-lg-4">
            <div className="col-lg-6 col-xl-7 text-center">
              <div
                className="owl-carousel testimonial-style-02"
                data-nav-arrow="true"
                data-nav-dots="false"
                data-items={1}
                data-lg-items={1}
                data-md-items={1}
                data-sm-items={1}
                data-space={0}
                data-autoheight="true"
              >
                <div className="item">
                  <div className="video-image">
                    <img
                      className="img-fluid w-100"
                      src={yv2}
                      alt=""
                      style={{ height: 400, objectFit: "cover" }}
                    />
                    <a
                      className="popup-icon popup-youtube"
                      href="https://www.youtube.com/watch?v=-Vnq78z4ui0"
                    >
                      <i className="fa fa-play" />
                      {/* svg start */}
                      <div className="svg-item">
                        <svg
                          version="1.1"
                          id="Layer_2"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          height="48px"
                          viewBox="0 0 1920 48"
                          style={{ enableBackground: "new 0 0 1920 48" }}
                          xmlSpace="preserve"
                        >
                          <polygon
                            id="XMLID_1_"
                            className="st0"
                            fill="#ffffff"
                            points="1920,48 0,48 0,48 1920,0 "
                          />
                        </svg>
                      </div>
                      {/* svg end */}
                    </a>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-avatar">
                      <img
                        style={{ height: 120 }}
                        className="img-fluid rounded-circle"
                        src="images/team/01.jpg"
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        "Innovation and dedication are at the core of everything
                        we do at Lilysolutions."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">Lanre Olifade</h6>
                        <span>Founder/CEO</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="video-image">
                    <img
                      className="img-fluid w-100"
                      style={{ height: 400, objectFit: "cover" }}
                      src={yv1}
                      alt=""
                    />
                    {/* <div style={{ height: 400, background: "#1d1d1d" }}></div> */}

                    <a
                      className="popup-icon popup-youtube"
                      href="https://www.youtube.com/watch?v=diz32QVyBPk"
                    >
                      <i className="fa fa-play" />
                      {/* svg start */}
                      <div className="svg-item">
                        <svg
                          version="1.1"
                          id="Layer_3"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          height="48px"
                          viewBox="0 0 1920 48"
                          style={{ enableBackground: "new 0 0 1920 48" }}
                          xmlSpace="preserve"
                        >
                          <polygon
                            id="XMLID_2_"
                            className="st0"
                            fill="#ffffff"
                            points="1920,48 0,48 0,48 1920,0 "
                          />
                        </svg>
                      </div>
                      {/* svg end */}
                    </a>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-avatar">
                      <img
                        className="img-fluid rounded-circle"
                        src="images/team/07.jpg"
                        alt=""
                        style={{ height: 120 }}
                      />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        "Driving business growth through strategic planning is
                        my forte."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">William Chidiebere</h6>
                        <span>Business Development Officer</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="video-image">
                    <img
                      className="img-fluid w-100"
                      style={{ height: 400, objectFit: "cover" }}
                      src={yv3}
                      alt=""
                    />
                    {/* <div style={{ height: 400, background: "#1d1d1d" }}></div> */}

                    <a
                      className="popup-icon popup-youtube"
                      href="https://www.youtube.com/watch?v=RpgRGo7KtEM"
                    >
                      <i className="fa fa-play" />
                      {/* svg start */}
                      <div className="svg-item">
                        <svg
                          version="1.1"
                          id="Layer_4"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          height="48px"
                          viewBox="0 0 1920 48"
                          style={{ enableBackground: "new 0 0 1920 48" }}
                          xmlSpace="preserve"
                        >
                          <polygon
                            id="XMLID_3_"
                            className="st0"
                            fill="#ffffff"
                            points="1920,48 0,48 0,48 1920,0 "
                          />
                        </svg>
                      </div>
                      {/* svg end */}
                    </a>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-avatar">
                      <img
                        className="img-fluid rounded-circle"
                        src="images/team/10.jpg"
                        style={{ height: 120 }}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content">
                      <p>
                        "Every picture tells a story, and I'm here to capture
                        it."
                      </p>
                    </div>
                    <div className="testimonial-author">
                      <div className="testimonial-name">
                        <h6 className="mb-1">Kelvin Elisha</h6>
                        <span>Photographer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-5 align-self-center ps-0 ps-lg-5 mt-5 mt-lg-0">
              <div className="ps-3 ps-lg-4">
                <h2 className="mb-4 mb-lg-5">
                  Nearly 1800 clients and 500 projects across the globe.
                </h2>
                <Link
                  to="/testimonial"
                  className="btn btn-primary-round btn-round mb-3"
                >
                  Client Success Stories
                  <i className="fas fa-arrow-right ps-3" />
                </Link>
                <hr />
                <h5 className="text-primary mt-md-4 mt-lg-5 mb-3 pt-3">
                  Awards and Nominees
                </h5>
                <div className="row">
                  <div className="col-lg-6 col-sm-3 col-6 mb-3 mb-md-0">
                    <img
                      className="img-fluid grayscale pt-4 w-75"
                      src="images/award-logo/01.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 col-sm-3 col-6 mb-3 mb-md-0">
                    <img
                      className="img-fluid grayscale pt-4 w-75"
                      src="images/award-logo/05.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 col-sm-3 col-6 ">
                    <img
                      className="img-fluid grayscale pt-4 pt-lg-5 w-75"
                      src="images/award-logo/03.svg"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-6 col-sm-3 col-6 ">
                    <img
                      className="img-fluid grayscale pt-4 pt-lg-5 w-75"
                      src="images/award-logo/04.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    Testimonial and Brands */}

      {/*=================================
    Counter */}
      <section style={{ marginBottom: 50 }} className="bg-dark py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
              <div className="counter">
                <div className="counter-icon">
                  <i className="flaticon-emoji" />
                </div>
                <div className="counter-content">
                  <span className="timer" data-to={1790} data-speed={10000}>
                    1790
                  </span>
                  <label>Happy Clients</label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-4 mb-lg-0">
              <div className="counter">
                <div className="counter-icon">
                  <i className="flaticon-trophy" />
                </div>
                <div className="counter-content align-self-center">
                  <span className="timer" data-to={6} data-speed={10000}>
                    6
                  </span>
                  <label>Years</label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
              <div className="counter">
                <div className="counter-icon">
                  <i className="flaticon-strong" />
                </div>
                <div className="counter-content">
                  <span className="timer" data-to={491} data-speed={10000}>
                    491
                  </span>
                  <label>Finished Projects</label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="counter">
                <div className="counter-icon">
                  <i className="flaticon-icon-149196" />
                </div>
                <div className="counter-content">
                  <span className="timer" data-to={1090} data-speed={10000}>
                    1090
                  </span>
                  <label>Media Posts</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    Counter */}

      {/*=================================
    Articles */}
      <section className="space-ptb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center">
                <h2>Latest news and inspirational stories</h2>
                <p>
                  Check out our latest blog posts, articles, client success
                  stories and essential announcement.
                </p>
              </div>
            </div>
          </div>
          {blogLoading ? (
            <Loading />
          ) : (
            <div className="row">
              <div className="col-md-7 col-lg-8 mb-4">
                <div className="blog-post blog-post-sticky">
                  <div className="blog-post-image">
                    <img
                      className="img-fluid"
                      style={{ height: 400, width: "100%", objectFit: "cover" }}
                      src={blogs?.[0]?.coverUrl}
                      alt=""
                    />
                  </div>
                  <div className="blog-post-content">
                    <div className="blog-post-info">
                      <div className="blog-post-author">
                        <Link
                          to={"/blog?id=" + blogs?.[0]?.id}
                          className="btn btn-light-round btn-round text-primary"
                        >
                          {blogs?.[0]?.tag}
                        </Link>
                      </div>
                      <div className="blog-post-date">
                        <span>{blogs?.[0]?.createdAt?.slice(0, 10)}</span>
                      </div>
                    </div>
                    <div className="blog-post-details">
                      <h5 className="blog-post-title">
                        <Link to={"/blog?id=" + blogs?.[0]?.id}>
                          {blogs?.[0]?.title}
                        </Link>
                      </h5>
                      <p className="mb-0">{blogs?.[0]?.summary}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-4">
                {blogs?.map((item, i) => {
                  if (i === 0) {
                    return null;
                  } else {
                    return (
                      <div key={item?.id} className="blog-post mb-4">
                        <div className="blog-post-image">
                          <img
                            className="img-fluid"
                            src={item?.coverUrl}
                            style={{ height: 200, objectFit: "cover" }}
                            alt=""
                          />
                        </div>
                        <div className="blog-post-content">
                          <div className="blog-post-info">
                            <div className="blog-post-author">
                              <Link
                                to={"/blog?id=" + item?.id}
                                className="btn btn-light-round btn-round text-primary"
                              >
                                {item?.tag}
                              </Link>
                            </div>
                            <div className="blog-post-date">
                              <span>{item?.createdAt?.slice(0, 10)}</span>
                            </div>
                          </div>
                          <div className="blog-post-details">
                            <h5 className="blog-post-title">
                              <Link to={"/blog?id=" + item?.id}>
                                {item?.title}
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                {/* <div className="blog-post mb-4">
                <div className="blog-post-image">
                  <img className="img-fluid" src="images/blog/03.jpg" alt="" />
                </div>
                <div className="blog-post-content">
                  <div className="blog-post-info">
                    <div className="blog-post-author">
                      <a
                        href="#"
                        className="btn btn-light-round btn-round text-primary"
                      >
                        Operations
                      </a>
                    </div>
                    <div className="blog-post-date">
                      <a href="#">Sep 19, 2020</a>
                    </div>
                  </div>
                  <div className="blog-post-details">
                    <h5 className="blog-post-title">
                      <a href="blog-detail.html">
                        Five reasons why you must create a website for your
                        Portfolio
                      </a>
                    </h5>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          )}
          <hr className="m-0 mt-4" />
          <div className="row mt-4 mt-md-5">
            <div className="col-12 d-md-flex justify-content-center align-items-center text-center">
              <p className="mb-3 mb-md-0 mx-0 mx-md-3 text-light">
                We have articles on a range of topics
              </p>
              <Link
                to="/blogs"
                className="btn btn-primary-round btn-round mx-0 mx-md-3"
              >
                View all Blogs
                <i className="fas fa-arrow-right ps-3" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
    Articles */}

      {/*=================================
    Action Box */}
      <ActionBox />
      {/*=================================
    Action Box */}
    </>
  );
};

export default Home;
