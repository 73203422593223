import { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MainLayout from "./layouts/MainLayout";
import About from "./pages/About";
import Howwework from "./pages/Howwework";
import Ourteam from "./pages/Ourteam";
import Missionandvision from "./pages/Missionandvision";
import Ourvalue from "./pages/Ourvalue";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Service from "./pages/Service";
import Pricing from "./pages/Pricing";
import Testemonial from "./pages/Testemonial";
import Termsandconditions from "./pages/Termsandconditions";
import Privacypolicy from "./pages/Privacypolicy";
import Error404 from "./pages/Error404";
import Comingsoon from "./pages/Comingsoon";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import "./assets/styles/css/portfolio.css";
import Blog from "./pages/Blog";
import Blogdetail from "./pages/Blogdetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const Home = lazy(() => import("./pages/Home"));
const Portfolio = lazy(() => import("./pages/Portfolio"));

function App() {
  return (
    <div>
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTYID}
        widgetId={process.env.REACT_APP_TAWK_wIDGETID}
      />
      <ToastContainer position="top-right" />

      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="how-we-work" element={<Howwework />} />
            <Route path="our-team" element={<Ourteam />} />
            <Route path="mission-vision" element={<Missionandvision />} />
            <Route path="our-value" element={<Ourvalue />} />
            <Route path="careers" element={<Careers />} />
            <Route path="contact" element={<Contact />} />
            <Route path="faq" element={<Faq />} />
            <Route path="service" element={<Service />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="testimonial" element={<Testemonial />} />
            <Route path="terms-conditions" element={<Termsandconditions />} />
            <Route path="privacy" element={<Privacypolicy />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="coming-soon" element={<Comingsoon />} />
            <Route path="blogs" element={<Blog />} />
            <Route path="blog" element={<Blogdetail />} />

            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
