import React, { useEffect, useState } from "react";
import CBoxes from "../components/CBoxes";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllPrices } from "../api/main";
import Loading from "../components/Loading";

const Pricing = () => {
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const handleFetchPrice = async () => {
      setLoading(true);
      try {
        const res = await getAllPrices();

        setPrices(res.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <section className="header-inner bg-overlay-gradient headerImgAlt coverBlack">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <div className="header-inner-title text-center">
                <h1 className="text-white fw-normal">Pricing Plans</h1>
                <p className="text-white mb-0">
                  Plans for the needs of every business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <nav className="pricing-tab">
                    <span className="monthly-tab">Monthly </span>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pb-4 pb-md-0">
                  <div className="pricing">
                    <h4 className="pricing-title">{prices?.[0]?.name}</h4>
                    <p className="mb-0">
                      Only the basics - Everything you need to get started. Best
                      for startup
                    </p>
                    <span className="pricing-price">
                      <sup>
                        <s>N</s>
                      </sup>
                      <strong>{prices?.[0]?.amount}</strong>
                      /month
                    </span>
                    <ul className="list-unstyled pricing-list">
                      <li>
                        Entry-level digital marketing and advertising services
                      </li>
                      <li>Standard website design and maintenance</li>
                      <li>
                        Basic branding services including logo design/li&gt;
                      </li>
                      <li>Initial business strategy consultation</li>
                      <li>Limited media relations support</li>
                    </ul>
                    <Link
                      to="/contact"
                      className="btn btn-light-round btn-round"
                    >
                      Select Plan
                      <i className="fas fa-arrow-right ps-3" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 pb-4 pb-md-0">
                  <div className="pricing active">
                    <h4 className="pricing-title">{prices?.[2]?.name}</h4>
                    <p className="mb-0">
                      When you are ready to grow, We will grow with you. Get a
                      Standard plan
                    </p>
                    <div className="pricing-price">
                      <div className="monthly-price">
                        <sup>
                          <s>N</s>
                        </sup>
                        <strong>{prices?.[2]?.amount}</strong>/month
                      </div>
                    </div>
                    <ul className="list-unstyled pricing-list">
                      <li>Comprehensive digital marketing campaigns</li>
                      <li>Custom website development with enhanced features</li>
                      <li>
                        Full branding services including visual identity and
                        brand collateral
                      </li>
                      <li>Ongoing business strategy and support</li>
                      <li>Media relations and public relations strategy</li>
                    </ul>
                    <Link
                      to="/contact"
                      className="btn btn-light-round btn-round"
                    >
                      Select Plan
                      <i className="fas fa-arrow-right ps-3" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pricing">
                    <h4 className="pricing-title">{prices?.[1]?.name}</h4>
                    <p className="mb-0">
                      Ready to kick it up a notch? Go for big with Professional
                      membership
                    </p>
                    <span className="pricing-price">
                      <sup>
                        <s>N</s>
                      </sup>
                      <strong>{prices?.[1]?.amount}</strong>
                      /month
                    </span>
                    <ul className="list-unstyled pricing-list">
                      <li>
                        Advanced digital marketing and advertising strategies
                      </li>
                      <li>
                        Premium web development including e-commerce solutions
                      </li>
                      <li>
                        Extensive branding services including UI/UX design
                      </li>
                      <li>Full-scale business support and consultancy</li>
                      <li>
                        Comprehensive media relations and crisis management
                      </li>
                    </ul>
                    <Link
                      to="/contact"
                      className="btn btn-light-round btn-round"
                    >
                      Select Plan
                      <i className="fas fa-arrow-right ps-3" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>

      <section className="pb-4 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We excel in our industry so that you can get in yours.</h2>
                <p>
                  See some of the industries we have vast experience working in
                  as a full-service marketing agency.
                </p>
              </div>
            </div>
          </div>
          <div className="row category category-grid-style-02">
            <div className="col-12">
              <ul className="list-unstyled">
                <li>
                  <span className="industriesExp">Automotive</span>
                </li>
                <li>
                  <span className="industriesExp">Communication Services</span>
                </li>
                <li>
                  <span className="industriesExp">Consumer Packaged Goods</span>
                </li>
                <li>
                  <span className="industriesExp">Education</span>
                </li>
                <li>
                  <span className="industriesExp">Financial Services</span>
                </li>
                <li>
                  <span className="industriesExp">Healthcare</span>
                </li>
                <li>
                  <span className="industriesExp">
                    Tours &amp; Travel Software
                  </span>
                </li>
                <li>
                  <span className="industriesExp">
                    Industrial and Manufacturing
                  </span>
                </li>
                <li>
                  <span className="industriesExp">
                    Logistics and Distribution
                  </span>
                </li>
                <li>
                  <span className="industriesExp">Media and Entertainment</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb bg-dark text-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-title text-center">
                <h2 className="text-white">
                  How Lilysolutions assist your business
                </h2>
                <p className="text-white">
                  At Lilysolutions Limited, we understand the unique challenges
                  that businesses face in today’s dynamic market. Our
                  comprehensive range of services is designed to provide you
                  with the support and expertise needed to elevate your brand
                  and achieve sustainable growth. Here's how we assist your
                  business
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-4 mb-md-5">
                <h5 className="text-white mb-2">Customized Solutions</h5>
                <p className="mb-0">
                  We don’t believe in one-size-fits-all. Our team works closely
                  with you to understand your specific needs and develop
                  tailored solutions that align with your business goals. From
                  bespoke website design to strategic marketing campaigns, every
                  service is crafted to meet your unique requirements.
                </p>
              </div>
              <div className="mb-4 mb-md-5">
                <h5 className="text-white mb-2">
                  Digital Marketing and Advertising
                </h5>
                <p className="mb-0">
                  Enhance your brand’s visibility and reach your target audience
                  effectively with our strategic digital marketing services. We
                  leverage the latest tools and techniques in SEO, paid media,
                  social media management, and influencer marketing to create
                  impactful campaigns that drive results.
                </p>
              </div>
              <div className="mb-4 mb-md-5">
                <h5 className="text-white mb-2">
                  Cutting-Edge Web Development
                </h5>
                <p className="mb-0">
                  Our web development team excels in creating visually
                  appealing, user-friendly, and responsive websites. Whether you
                  need a powerful web application, an e-commerce platform, or a
                  bespoke website, we have the expertise to deliver solutions
                  that enhance user experience and drive business growth.
                </p>
              </div>
              <div className="mb-4 mb-md-5">
                <h5 className="text-white mb-2">
                  Innovative Branding Services
                </h5>
                <p className="mb-0">
                  Establish a strong brand identity with our comprehensive
                  branding services. From logo design to visual identity
                  creation, we ensure that every element of your brand resonates
                  with your target audience and communicates your values
                  effectively. Our branding solutions help you stand out in a
                  crowded market.
                </p>
              </div>
              <div className="mb-4 mb-md-0">
                <h5 className="text-white mb-2">
                  Business Support and Strategy
                </h5>
                <p className="mb-0">
                  Optimize your business processes and achieve long-term growth
                  with our expert business support and strategy consulting. We
                  provide actionable insights and guidance to streamline
                  operations, enhance efficiency, and drive profitability. Our
                  services include business strategy consulting, corporate
                  communications, and media relations.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-4 mb-md-5">
                <h5 className="text-white mb-2">
                  Creative Content and Media Production
                </h5>
                <p className="mb-0">
                  Engage your audience with high-quality, creative content and
                  media production. Our team of skilled professionals offers
                  services in 3D animation, cinematography, photography, graphic
                  designing, and video editing. We create compelling content
                  that captures attention and communicates your brand story
                  effectively.
                </p>
              </div>
              <div className="mb-4 mb-md-5">
                <h5 className="text-white mb-2">Training and Development</h5>
                <p className="mb-0">
                  Invest in your team’s growth with our expert training
                  programs. We offer comprehensive training in web development,
                  digital marketing, 3D animation, cinematography, photography,
                  graphic designing, drone piloting, and video editing. Empower
                  your employees with the skills they need to excel in their
                  roles.
                </p>
              </div>
              <div className="mb-4 mb-md-5">
                <h5 className="text-white mb-2">
                  Our Commitment to Your Success
                </h5>
                <p className="mb-0">
                  Since our inception in 2018, Lilysolutions Limited has been
                  dedicated to helping businesses thrive. We collaborate with
                  industry leaders and innovators to deliver solutions that make
                  a difference. Our award-winning services are trusted by
                  Fortune 100 companies and established brands. Ready to take
                  your business to the next level? Partner with Lilysolutions
                  Limited and experience the difference our expertise can make.
                  Contact us today to learn more about how we can
                  assist&nbsp;your&nbsp;business
                </p>
              </div>
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h5 className="text-white mb-4">
                    Have questions about pricing? Ask our sales team.
                  </h5>
                  <a
                    href="mailto:info@lilysolutions.org"
                    className="btn btn-primary-round btn-round text-white"
                  >
                    reach out through Email
                    <i className="fas fa-arrow-right ps-3" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <p className="mb-0">
                DISCLAIMER - All prices on this website are subject to change
                without notice. We all carry a lot of baggage, thanks to our
                upbringing. The majority of people carry with them, an entire
                series of self-limiting beliefs that will absolutely stop, and
                hold them back from, success. Things like “I’m not good enough”,
                “I’m not smart enough”, “I’m not lucky enough”, and the worst,
                “I’m not worthy” are but a few of the self-limiting beliefs I
                have encountered. We carry them with us like rocks in a
                knapsack.
              </p>
            </div>
          </div>
        </div>
      </section>

      <CBoxes />
    </div>
  );
};

export default Pricing;
