import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { fetchSiteInfo } from "../api/main";
import { toast } from "react-toastify";

const MainLayout = () => {
  const [loading, setLoading] = useState(false);
  const [siteInfo, setSiteInfo] = useState(null);
  const { pathname } = useLocation();

  const loadScript = function (src) {
    const tag = document.createElement("script");
    tag.async = true;
    tag.src = src;
    document.getElementsByTagName("body")[0].appendChild(tag);
  };

  useEffect(() => {
    // loadScript("js/jquery-3.6.0.min.js");
    // loadScript("js/popper/popper.min.js");
    // loadScript("js/bootstrap/bootstrap.min.js");

    // loadScript("js/jquery.appear.js");
    // loadScript("js/swiper/swiper.min.js");
    // loadScript("js/swiperanimation/SwiperAnimation.min.js");
    // loadScript("js/counter/jquery.countTo.js");
    // loadScript("js/owl-carousel/owl.carousel.min.js");
    // loadScript("js/magnific-popup/jquery.magnific-popup.min.js");
    loadScript("js/horizontal-timeline/horizontal-timeline.js");
    // loadScript("js/shuffle/shuffle.min.js");
    // loadScript("js/jarallax/jarallax-video.min.js");
    // loadScript("js/jarallax/jarallax.min.js");

    loadScript("js/custom.js");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const handleFetchSiteInfo = async () => {
      setLoading(true);
      try {
        const response = await fetchSiteInfo();

        setSiteInfo(response.data);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchSiteInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("siteInfo", siteInfo);

  return (
    <div>
      {loading && (
        <div className="pageLoading">
          <Loading size={100} type="bars" />
        </div>
      )}

      <Topbar siteInfo={siteInfo} />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      <Footer siteInfo={siteInfo} />

      {/* <div id="back-to-top" className="back-to-top">
        up
      </div> */}
    </div>
  );
};

export default MainLayout;
