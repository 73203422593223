import React, { useEffect, useState } from "react";
import ActionBox from "../components/ActionBox";
import blog1 from "../assets/images/blog/1.jpg";
import blog2 from "../assets/images/blog/2.jpg";
import blog3 from "../assets/images/blog/3.jpg";
import blog4 from "../assets/images/blog/4.jpg";
import blog5 from "../assets/images/blog/5.jpg";
import blog6 from "../assets/images/blog/6.jpg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchBlogs } from "../api/main";
import Loading from "../components/Loading";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogLoading, setBlogLoading] = useState(false);

  //   const blogs = [
  //     {
  //       id: "hnbtr54t545gtvr54",
  //       title: "Top 5 Business Branding Mistakes",
  //       desc: `Branding is a vital part of business, mostly done at the beginning of a
  // business venture. However, these are the top 5 mistakes business owners
  // make and how to correct them.`,
  //       img: blog1,
  //       tag: "Business",
  //       date: "May 16, 2024",
  //     },
  //     {
  //       id: "sdsdfr38744g3h4g3h",
  //       title: "How to turn your Brand into a Legacy",
  //       desc: `Starting your brand sometimes have future purposes but sometimes the future is not beyond you. Some brands outlive the visioner while some don’t. If you think your brand is meant to outlive you, you need to watch this video to know how to turn your brand into a legacy that will outlive you and your generation.`,
  //       img: blog2,
  //       tag: "Business",
  //       date: "Feb 23, 2024",
  //     },
  //     {
  //       id: "alkiae7232k2j3e89w32",
  //       title: "How to Switch from Employee to Business owner",
  //       desc: `Been an employee is not the end of the road, that your business dream can be born, this transition is possible! Watch Solutions Podcast to know how!`,
  //       img: blog3,
  //       tag: "Business",
  //       date: "Dec 3, 2023",
  //     },
  //     {
  //       id: "gs56d734u873euwrer",
  //       title: "Why you Need to Work for Someone",
  //       desc: `Are you young in the labour market or fresh out of school and you are passionate about starting a business. That’s a brilliant idea, but in this episode, we expose to you the reason why you need to work first before going out on your own.`,
  //       img: blog4,
  //       tag: "Career",
  //       date: "Dec 3, 2023",
  //     },
  //     {
  //       id: "fhduf6e643h4jw6e34ew",
  //       title: "A Key to Thriving Business",
  //       desc: `Been stuck in life or business is as a result of the lack of fresh ideas. Sam Adeyemi said Ideas rule the world and this is very true. Learn how innovation applies to your life and business and how you can become an idea factory.`,
  //       img: blog5,
  //       tag: "Business",
  //       date: "Dec 3, 2023",
  //     },
  //     {
  //       id: "kllksdri7384jkw84233re",
  //       title: "Waiting for Clients or Getting Clients",
  //       desc: `Starting a business is one thing, getting clients or making sales is another hurdle. Are you the type that waits for clients or customers? Well find out about these two approaches to marketing and find out the most appropriate for you.`,
  //       img: blog6,
  //       tag: "Business",
  //       date: "Dec 3, 2023",
  //     },
  //   ];

  useEffect(() => {
    const handleFetchBlogs = async () => {
      setBlogLoading(true);
      try {
        const res = await fetchBlogs(1, 50);

        setBlogs(res.data?.blogs);
        setBlogLoading(false);
      } catch (err) {
        setBlogLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <section
        className="header-inner bg-overlay-black-70 headerImg"
        // style={{ backgroundImage: 'url("images/header-inner/02.jpg")' }}
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8">
              <div className="header-inner-title text-center position-relative">
                <h1 className="text-white fw-normal">Blog</h1>
                <p className="text-white mb-0">
                  The sad thing is the majority of people have no clue about
                  what they truly want. They have no clarity. When asked the
                  question
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="space-ptb">
        <div className="container">
          {blogLoading ? (
            <Loading />
          ) : (
            <div className="row">
              {blogs?.map((item) => {
                return (
                  <div key={item?.id} className="col-lg-4 col-md-6 mb-4">
                    <div className="blog-post">
                      <div className="blog-post-image">
                        <img
                          className="img-fluid"
                          src={item?.coverUrl}
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            height: 200,
                          }}
                        />
                      </div>
                      <div className="blog-post-content">
                        <div className="blog-post-info">
                          <div className="blog-post-author">
                            <Link
                              to={"/blog?id=" + item?.id}
                              className="btn btn-light-round btn-round text-primary"
                            >
                              {item?.tag}
                            </Link>
                          </div>
                          <div className="blog-post-date">
                            <span>{item?.createdAt?.slice(0, 10)}</span>
                          </div>
                        </div>
                        <div className="blog-post-details">
                          <h5 className="blog-post-title">
                            <Link to={"/blog?id=" + item?.id}>
                              {item?.title}
                            </Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className="row mt-4 mt-md-5">
            <div className="col-12 text-center">
              <a href="#" className="btn btn-primary-round btn-round">
                Load More
                <i className="fas fa-arrow-right ps-3" />
              </a>
            </div>
          </div> */}
        </div>
      </section>

      <ActionBox />
    </div>
  );
};

export default Blog;
