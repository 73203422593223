import ReactLoading from "react-loading";

const Loading = ({ button, size, type }) => {
  return (
    <>
      {button ? (
        <ReactLoading
          color="#fff"
          width={size ? size : 30}
          height={size ? size : 30}
          type={type ? type : "spin"}
        />
      ) : (
        <div className="loadingCon">
          <ReactLoading
            color="#022d62"
            width={size ? size : 50}
            height={size ? size : 50}
            type={type ? type : "spin"}
          />
        </div>
      )}
    </>
  );
};

export default Loading;
